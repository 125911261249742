import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import { serverIp } from '../Config'
const initialState={
 
        username:'',
        numcoin:0,
        tapedCoin:0,
        invcode:'',
        isloading:false,
        error:null,
   
}
export const getCoin=createAsyncThunk("coin/getCoin",async(user,{rejectedWithValue})=>

    {
        try{
const response= await axios.get(serverIp+"/getcoin?username="+user)
console.log(response.data)
return response.data
        }
        catch(error)
        {
            return rejectedWithValue(error.response.data)
        }
    }
)






const coinSlice=createSlice(
{
name:"coin",
initialState,
reducers:{
    increment: (state) => {
        state.tapedCoin += 1;
        
      }
},
extraReducers:(builder)=>{
builder.addCase(getCoin.pending,(state,action)=>
    {
        return {
            ...state,
            isloading:true,
            }
    }
).addCase(getCoin.fulfilled,(state,action)=>
        {
            return {
                ...state,
                username:action.payload.username,
                numcoin:action.payload.numcoin,
                invcode:action.payload.invcode,
               isloading:false
                }
        }).addCase(getCoin.rejected,(state,action)=>
            {
                return {
                    ...state,
                   error:action.payload,
                   isloading:false
                    }
            })


}
}
)






export const { increment } = coinSlice.actions;
 export default coinSlice.reducer