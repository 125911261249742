import background from '../asset/background.png'
const DeviceType=()=>
{
    return(
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundColor: '#aaccdd',
            minHeight:'100vh',
            marginTop:'-6%',
        }} >
<h1 style={{
          padding:'25%'}}>
            Please use Only Mobile Devices!!</h1>
        </div>
    )
}
export default DeviceType