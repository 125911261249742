import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import axios from "axios"
import { useState, useEffect,useRef } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Button from '@mui/material/Button';
import clipboardCopy from 'clipboard-copy';
import Paper from '@mui/material/Paper';
import background from '../asset/background.png'
import {bot_token,serverIp,botusername,referedCoin} from '../Config.js';
const ReferalPage=()=>
{
  const TgProfilePic=({ user_id })=>
     {
    const [profile, setProfile] = useState('')

    useEffect(() => {
      const fetchProfile = async () => {
        const profiles = await axios.get(`https://api.telegram.org/bot${bot_token}/getUserProfilePhotos?user_id=${user_id}`)
        const file_path = await axios.get(`https://api.telegram.org/bot${bot_token}/getFile?file_id=${profiles.data.result.photos[0][2].file_id}`)
        setProfile(`https://api.telegram.org/file/bot${bot_token}/${file_path.data.result.file_path}`)
      }
      fetchProfile()
    }, [])


    return (
      <>
     
        <ListItemAvatar >
      <Avatar src={profile} style={{marginLeft:10}} />
    </ListItemAvatar>
      </>
    );
  }
 









  
  const[referedUsers,setreferedUsers]=useState([ ])
  let myArray = [];

  const getReferedUsers=async()=>
    {
      const response =  await axios.get(serverIp+'/getreferduser?username=AndroDems')
      setreferedUsers(response.data)
      
      for(let i=0;i<response.data.length;i++)
      {
        myArray.push(response.data[i].user_id);
      
      }
      
     console.log(myArray)
    }
    useEffect(()=>
    {
      
      getReferedUsers()
    
    },[])

  const {invcode}=useSelector(state=>state.coin)
const[copy,setCopy]=useState('Copy')





  const handleCopy = async () => {
  
  clipboardCopy(botusername+'?start='+invcode)
      .then(() => {
       setCopy('Copied')
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
    }
  
    return(
   <div style={{
    minHeight:'100vh',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundColor: '#aaccdd',
    marginTop:'-6%'
}} > 
   <Paper elevation={3} style={{
            height:'auto',
            padding:'5%',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundColor: '#aaccdd',
        }}  >
    <h1>your referal link</h1>
   <h6>{botusername}+?start={invcode}</h6>
        <Button variant="outlined" onClick={handleCopy} style={{width:'88%',
            height:'auto',
            margin:'5%'
        }} >{copy}</Button>
        </Paper>
        <Paper elevation={3} style={{
           
            padding:'5%',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundColor: '#aaccdd',
            
        }}  >
<List>
{referedUsers ?( referedUsers.map(({ username, numcoin,user_id }, index) => (
 

  <div>
  <ListItemButton key={index} href={'https://t.me/' + username} target='_blank'>
   
    <ListItemAvatar >
     <TgProfilePic user_id={user_id} />
   
    </ListItemAvatar>
    <ListItemText primary={username}  />
  </ListItemButton>
  </div>
 
))):(<h1>There is no referals Please refer your friends and get {referedCoin} coin</h1>)}
</List>
</Paper>
</div>
    )

  }



  export default ReferalPage