import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import background from '../asset/background.png'
import D from '../asset/D.gif'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import axios from "axios"
import { useState, useEffect } from 'react'
import { serverIp } from '../Config.js';
const TaskPage=()=>
{
const[tasklist,settaskList]=useState([ ])

const getTask=async()=>
  {
    const response= await axios.get(serverIp+'/gettask')
    settaskList(response.data)
  }
  useEffect(()=>
  {
    getTask()
   
  },[])
 




    return(
        <div style={{
            backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundColor: '#aaccdd',
    minHeight:'100vh',
    marginTop:'-6%'
      }}>
<h3 style={{color:'#ffccaa'
            
        }}>If you miss any task your account is at risk</h3>
<Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {

        },
      }}
    >
    
      
        <img src={D} style={{width:'90%',
            height:'40%',
            margin:'5%',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundColor: '#aaccdd',
        }}/>
        

        <Paper elevation={3} style={{
          backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundColor: '#aaccdd',
        }} >

     <List>
{tasklist ?( tasklist.map(({  rewaredCoin,ylink,yid }, index) => (
    <div>
      
  <ListItemButton key={index } href={ylink}  >
    <ListItemAvatar>
    <YouTubeIcon fontSize="large" sx={{ color: '#ff0000' }}/>
    </ListItemAvatar>
    <ListItemText primary="Subscribe our Youtube Channel" secondary={rewaredCoin} primaryTypographyProps={{
            style: {
              color: '#ff0000', // Set primary text color to red
            },
          }}
          secondaryTypographyProps={{
            style: {
              color: '#f5be4a',
              fontSize:'15px' // Set secondary text color to green
            },
          }} />
    <IconButton onClick={(event)=>{event.preventDefault();
       
        window.open(ylink, "_blank", "noreferrer");
        localStorage.setItem("youtubetask"+yid,true)
        localStorage.setItem("rewaredCoin"+yid,rewaredCoin)
        localStorage.setItem("totaltask",tasklist.length)
    }}  ><ArrowForwardIosIcon/></IconButton>

  </ListItemButton>
  
  </div>
))):(<h1>There is no Available Task</h1>)}
</List>
        
        </Paper>

    </Box>

    </div>
    )
}
export default TaskPage