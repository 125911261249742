import background from '../asset/background.png'
const SameDevice=()=>
{
    return(
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundColor: '#aaccdd',
            minHeight:'100vh',
            marginTop:'-6%',
        }} >
<h1 style={{
          padding:'25%'}}>
           You can not use the same device with different user!!</h1>
           <h2  style={{
            color:'#ff0000',
          padding:'25%'}}>Please use another device which is not registered </h2>
        </div>
    )
}
export default SameDevice