import Paper from '@mui/material/Paper';
import background from '../asset/background.png'
import axios from "axios"
import { useState, useEffect } from 'react'
import { serverIp } from '../Config.js';
const StatusPage=()=>
    {

        const[stat,setstat]=useState([ ])

        const getStat=async()=>
          {
            const response= await axios.get(serverIp+'/getstat')
            setstat(response.data)
          }
          useEffect(()=>
          {
            getStat()
           
          },[])

        return(

    stat.map(({ totalshare,totaltouch,totaluser,dailyuser,onlineuser }, index) => (
  <div
  style={{
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundColor: '#aaccdd',
    marginTop:'-6%',
    minHeight:'100vh'
}}
  >

            <Paper elevation={3} style={{width:'88%',
                height:"auto",
                margin:'5%',
                padding:'5%',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundColor: '#aaccdd',
            }} >

      
      
<h3>Total Share Balance:</h3>
<h1>{totalshare}</h1>
</Paper>
 <Paper elevation={3} style={{width:'88%',
     margin:'5%',
    padding:'5%',
    backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundColor: '#aaccdd',
}} >
<h4>Total Touches:</h4>
<h3>{totaltouch}</h3>
<h4>Total Players:</h4>
<h3>{totaluser}</h3>
<h4>Daily Users :</h4>
<h3>{dailyuser}</h3>
<h4>Online Players :</h4>
<h3>{onlineuser}</h3>


                </Paper>

                </div>


            ))






        )
    }
    export default StatusPage