import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { Outlet, useNavigate } from "react-router-dom";
import {useDispatch,useSelector} from 'react-redux'
import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import background from './asset/background.png'
export default function App() {
  const [value, setValue] = React.useState(1);
  const dispatch = useDispatch();
    const {username}=useSelector(state=>state.coin)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  
    function isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
    useEffect(() => {

      
      if (!isMobileDevice()) {
        navigate('devicetype');
        return
      }




   
      
    }, []);
  const navigate = useNavigate();
   useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
     let curretUser = window.Telegram.WebApp.initDataUnsafe.user.username;
     if(curretUser!=username)
      {

        if('undefined'!=username)
        {

        }
        else{
          navigate('samedevice');
        }
      
      }
    }
  }, [username]);




  
  return (

    <div sx={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundColor: '#aaccdd',
    }} 
    >
      <CssBaseline/>
     
      <Outlet/>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundColor: '#aaccdd',
       }} elevation={3}>
        <BottomNavigation
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundColor: '#aaccdd',
         }}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
           switch(newValue)
           {

case 0:
{
  navigate("/ref"); 
  break
}
case 1:
{
  navigate("/"); 
  break
}
case 2:
  {
    navigate("/task"); 
  break
  }
  case 3:
    {
      navigate("/stat"); 
    break
    }
           }
          }}
        >
          <BottomNavigationAction label="Referals" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Tap" icon={<FavoriteIcon />}  />
          <BottomNavigationAction label="Task" icon={<ArchiveIcon />} />
          <BottomNavigationAction label="Status" icon={<ArchiveIcon />} />
        </BottomNavigation>
      </Paper>
    </div>
 
  );
}
