import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import { configureStore } from '@reduxjs/toolkit';
import coinReducer from './redux/Coin';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { combineReducers } from '@reduxjs/toolkit';
import {

  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  } from 'redux-persist'
  
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Tappage from './pages/tap';
import ReferalPage from './pages/ReferalPage';
import TaskPage from './pages/TaskPage';
import StatusPage from './pages/StatusPage';
import DeviceType from './pages/DeviceType';
import SameDevice from './pages/SameDevice';


 
  const rootReducer = combineReducers({
   coin: coinReducer,
  })
  
 



const persistConfig = { 
    key: 'root',
    storage, 
}

const persistedReducer = persistReducer(persistConfig, rootReducer) // create a persisted reducer


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
  serializableCheck: {
  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
  }),
  })
const  persistor = persistStore(store);
function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 

  <Provider store={store}>
  <PersistGate loading={null} persistor={persistor}> 
  <BrowserRouter>
<Routes>

  <Route path='/' element={<App/>} >
  
  <Route index element={<Tappage/>}/>
  <Route path='/ref' element={<ReferalPage/>}/>
  <Route path='task' element={<TaskPage/>}/>
  <Route path='stat' element={<StatusPage/>}/>
  </Route>

  <Route path='devicetype' element={<DeviceType/>}/>
  <Route path='samedevice' element={<SameDevice/>}/>

  </Routes>
  </BrowserRouter>
    
  </PersistGate>
</Provider>
   



);

