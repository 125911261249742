import React, { useState, useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { getCoin,increment } from '../redux/Coin';
import { useNavigate } from 'react-router-dom';
import axios from "axios"
import Slider from '@mui/material/Slider';
import Paper from '@mui/material/Paper';
import giphy from '../asset/giphy.gif'
import background from '../asset/background.png'
import tapimage from '../asset/tapimage.png'
const Tappage = () => {
    const dispatch = useDispatch();
    const {username}=useSelector(state=>state.coin)
    const {numcoin}=useSelector(state=>state.coin)
    const {tapedCoin}=useSelector(state=>state.coin)
   const[sum,setSum]=useState(0)
   const [value, setValue] = useState(0);

const Add=async()=>
{
  let x=0
let y=await localStorage.getItem('totaltask')
  for(let i=1;i<=y;i++)
  {
    if(await localStorage.getItem('rewaredCoin'+i)==null||await localStorage.getItem('rewaredCoin'+i)==undefined )
   {
    continue
   }
   else{
   setSum(x+=parseInt(await localStorage.getItem('rewaredCoin'+i)))
   }
  }

}
useEffect(()=>
{

Add()
},[])



useEffect(()=>{
  dispatch(getCoin(window.Telegram.WebApp.initDataUnsafe.user.username))
  
},[username])






  const [tapPositions, setTapPositions] = useState([]);
  const[numberOfCoin,setNumberOfCoin]=useState(0)


  useEffect(() => {
   
    const interval = setInterval(() => {
      setTapPositions((prevPositions) => {
        const updatedPositions = prevPositions.map((position) => ({
          x: position.x,
          y: position.y - 2,
          opacity: position.opacity - 0.01,
        }));
        return updatedPositions.filter((position) => position.opacity > 0);
      });
    }, 16); // 60 frames per second

    return () => clearInterval(interval);
  }, []);

  const handleTouchStart = (e) => {

    const { clientX, clientY } = e.touches[0];
    setTapPositions((prevPositions) => [
      ...prevPositions,
      { x: clientX, y: clientY, opacity: 1 },
    ]);
    dispatch(increment())
  };


  return (
    <div style={{
    
       backgroundImage: `url(${background})`,
       backgroundSize: 'cover',
       backgroundColor: '#aaccdd',
      minHeight:'100vh',
      marginTop:'-6%'

   }}>
           
        <h1 style={{
        color:'#fff',
        marginLeft:'25%'
      }}> Welcome {username}</h1>
       <h3 style={{
        color: '#f5be4a',
        marginLeft:'25%'
      }}>
          {tapedCoin+numcoin+sum}
          </h3>

    <img src={tapimage} width='110%' height='110%'  onTouchStart={handleTouchStart} style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '100%',
        }}/>
      {tapPositions.map((position, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: position.x ,
            top: position.y ,
            width: 30,
            height: 30,
            backgroundColor: '#f5be4a',
            borderRadius: '50%',
            pointerEvents: 'none',
            opacity: position.opacity,
          }}
        >
            </div>
      ))}


      </div>
  );
};

export default Tappage;